import { ReactElement } from 'react';
import { ButtonProps } from './Button.types';
import { ButtonWrapper } from './styles';
import { Default } from './contentVariants/default';
import { Read } from './contentVariants/read';
import { Play } from './contentVariants/play';

export const Button = ({
  variant,
  loading,
  text,
  disabled,
  onClick,
  as,
  href,
  className,
  download,
}: ButtonProps): ReactElement => {
  const handleClick = () => {
    if (!disabled && !loading) onClick();
  };

  return (
    // TODO: investigate, seems like a know issue for sc with typescript, nothing serious
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ButtonWrapper
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      as={as}
      href={href}
      disabled={disabled}
      variant={variant}
      onClick={() => handleClick()}
      className={className}
      download={download}
    >
      {(!variant || variant === 'default') && (
        <Default text={text} loading={loading} variant={variant} />
      )}
      {variant === 'read' && <Read text={text} loading={loading} variant={variant} />}
      {variant === 'play' && <Play text={text} loading={loading} variant={variant} />}
    </ButtonWrapper>
  );
};
