import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import styled from 'styled-components';

import ArrowSVG from './arrowRight.svg';
import LoaderSVG from './loader.svg';
import PlaySVG from './play.svg';

type ButtonWrapperProps = {
  variant?: string;
  as?: string;
  href?: string;
};

const variants = {
  play: {
    fontSize: ({ theme }) => theme.typography.paragraph.fontSize.medium.pc,
    padding: '0.375rem 0.75rem',
    backgroundColor: ({ theme }) => theme.colors.background,
    textTransform: 'none',
    letterSpacing: '0px',
    border: ({ theme }) => `1px ${theme.colors.title} solid`,
    hover: {
      backgroundColor: ({ theme }) => theme.colors.title,
      color: ({ theme }) => theme.colors.background,
    },
    outline: {
      offset: '0.25rem',
      style: 'solid',
      width: '1px',
    },
    mobile: {
      fontSize: `${({ theme }) => theme.typography.paragraph.fontSize.medium.mobile}`,
    },
    loader: {
      strokeWidth: '1px',
      scale: '0.65',
    },
  },
  read: {
    fontSize: ({ theme }) => theme.typography.paragraph.fontSize.medium.pc,
    padding: '0.375rem 0.75rem',
    backgroundColor: ({ theme }) => theme.colors.backgroundButton,
    textTransform: 'none',
    letterSpacing: '0px',
    border: 'none',
    hover: {
      backgroundColor: ({ theme }) => theme.colors.menu,
      color: ({ theme }) => theme.colors.title,
    },
    outline: {
      offset: '0',
      style: 'none',
      width: 'none',
    },
    mobile: {
      fontSize: ({ theme }) => theme.typography.paragraph.fontSize.medium.mobile,
    },
    loader: {
      strokeWidth: '1px',
      scale: '0.65',
    },
  },
  default: {
    fontSize: ({ theme }) => theme.typography.paragraph.fontSize.large.pc,
    padding: '0.875rem 4rem',
    backgroundColor: ({ theme }) => theme.colors.background,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    border: ({ theme }) => `2px ${theme.colors.title} solid`,
    hover: {
      backgroundColor: ({ theme }) => theme.colors.title,
      color: ({ theme }) => theme.colors.background,
    },
    outline: {
      offset: '0.375rem',
      style: 'solid',
      width: '2px',
    },
    mobile: {
      fontSize: ({ theme }) => theme.typography.paragraph.fontSize.medium.pc,
    },
    loader: {
      strokeWidth: '2px',
      scale: '1',
    },
  },
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  border: ${({ variant }) => variants[variant]?.border ?? variants.default.border};
  padding: ${({ variant }) => variants[variant]?.padding ?? variants.default.padding};
  transition: all 0.3s ease-in-out;
  background-color: ${({ variant }) =>
    variants[variant]?.backgroundColor ?? variants.default.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: nowrap;

  &:focus-visible {
    outline-offset: ${({ variant }) =>
      variants[variant]?.outline.offset ?? variants.default.outline.offset};
    outline-width: ${({ variant }) =>
      variants[variant]?.outline.width ?? variants.default.outline.width};
    outline-style: ${({ variant }) =>
      variants[variant]?.outline.style ?? variants.default.outline.style};
  }

  &:disabled {
    opacity: 0.3;
  }

  &:hover:not(:disabled) {
    background-color: ${({ variant }) =>
      variants[variant]?.hover.backgroundColor ?? variants.default.hover.backgroundColor};
    color: ${({ variant }) => variants[variant]?.hover.color ?? variants.default.hover.color};
    cursor: pointer;
    & svg > path {
      stroke: ${({ variant, theme }) => variant !== 'read' && theme.colors.background};
    }
  }
`;

export const ButtonText = styled.span<{ variant?: string; loading?: boolean }>`
  text-transform: ${({ variant }) =>
    variants[variant]?.textTransform ?? variants.default.textTransform};
  font-weight: ${({ theme }) => theme.typography.paragraph.fontWeight.xLarge};
  font-size: ${({ variant }) =>
    variants[variant]?.mobile.fontSize ?? variants.default.mobile.fontSize};
  font-family: ${({ theme }) => theme.typography.paragraph.fontFamily.default};
  letter-spacing: ${({ variant }) =>
    variants[variant]?.letterSpacing ?? variants.default.letterSpacing};
  visibility: ${({ variant, loading }) => (loading && variant !== 'play' ? 'hidden' : 'visible')};

  @media ${MEDIA_QUERIES.tablet} {
    font-size: ${({ variant }) => variants[variant]?.fontSize ?? variants.default.fontSize};
  }
`;

export const Loader = styled(LoaderSVG)<{ variant?: string }>`
  animation: 1.5s spin infinite linear;
  & > path {
    transition: all 0.3s ease-in-out;
    stroke-width: ${({ variant }) =>
      variants[variant]?.loader.strokeWidth ?? variants.default.loader.strokeWidth};
  }
  @keyframes spin {
    from {
      transform: scale(
          ${({ variant }) => variants[variant]?.loader.scale ?? variants.default.loader.scale}
        )
        rotate(0deg);
    }
    to {
      transform: scale(
          ${({ variant }) => variants[variant]?.loader.scale ?? variants.default.loader.scale}
        )
        rotate(360deg);
    }
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Play = styled(PlaySVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > path {
    transition: all 0.3s ease-in-out;
    stroke: ${({ theme }) => theme.colors.title};
  }
`;

export const PlayIconWrapper = styled.div`
  margin-right: 0.5rem;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
`;

export const ArrowRight = styled(ArrowSVG)<{ loading?: boolean }>`
  margin-left: 0.5rem;
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  & > path {
    stroke: ${({ theme }) => theme.colors.title};
  }
`;
