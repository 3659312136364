import { ReactElement } from 'react';

import { ButtonText, Loader, LoaderWrapper, PlayIconWrapper, Play as PlayIcon } from '../../styles';
import { VariantProps } from '../Variants.types';

export const Play = ({ text, loading, variant }: VariantProps): ReactElement => {
  return (
    <>
      <PlayIconWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader variant={variant} />
          </LoaderWrapper>
        ) : (
          <PlayIcon />
        )}
      </PlayIconWrapper>
      <ButtonText loading={loading} variant={variant}>
        {text}
      </ButtonText>
    </>
  );
};
