import { ReactElement } from 'react';

import { ButtonText, Loader, ArrowRight, LoaderWrapper } from '../../styles';
import { VariantProps } from '../Variants.types';

export const Read = ({ text, loading, variant }: VariantProps): ReactElement => {
  return (
    <>
      <ButtonText loading={loading} variant={variant}>
        {text}
      </ButtonText>
      <ArrowRight loading={loading} />
      {loading && (
        <LoaderWrapper>
          <Loader variant={variant} />
        </LoaderWrapper>
      )}
    </>
  );
};
